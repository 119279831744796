<template>
  <div class="mainContain">
    <div class="box">
      <div class="ViewTitle">{{ title }}</div>
      <div class="contain gery">
        <el-form
          :label-position="labelPosition"
          label-width="110px"
          :model="form"
        >
          <p class="tips">
            <img loading="lazy" src="@/assets/tishi.png" alt="" srcset="" />
            <template v-if="hasOldPassword == 'true'">
              您已设置密码，输入旧密码即可修改新密码。
            </template>
            <template v-else> 您暂未设置密码，输入即可设置新密码。 </template>
          </p>
          <el-form-item label="旧密码" v-if="hasOldPassword == 'true'">
            <el-input
              v-model="form.oldpassword"
              show-password
              placeholder="请输入旧密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input
              v-model="form.password"
              show-password
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input
              v-model="form.requirePas"
              placeholder="请再次输入您的密码"
              show-password
            ></el-input>
          </el-form-item>
          <div class="submit" style="display: flex; justify-content: flex-end;margin-right: 5vh">
            <el-button class="cancleStyle" @click="cancleSubmit()"
            >取消</el-button
            >
            <el-button  class="confirmStyle" @click="submit()">完成</el-button>
          </div>
         </el-form>
      </div>
    </div>
    <div class="box" v-if="languageSelect === 'en'">
      <div class="ViewTitle">{{ titleEn }}</div>
      <div class="contain gery">
        <el-form
          :label-position="labelPosition"
          label-width="180px"
          :model="form"
          ><p class="tips">
            <img loading="lazy" src="@/assets/tishi.png" alt="" srcset="" />
            <template v-if="hasOldPassword == 'true'">
              You have set a password. Enter the old password to change the new
              password.
            </template>
            <template v-else>
              You have not set a password yet. Enter to set a new password.
            </template>
          </p>
          <el-form-item label="Old Password" v-if="hasOldPassword == 'true'">
            <el-input
              v-model="form.oldpassword"
              show-password
              placeholder="Please enter old password"
            ></el-input>
          </el-form-item>
          <el-form-item label="New Password">
            <el-input
              v-model="form.password"
              show-password
              placeholder="Please enter new password"
            ></el-input>
          </el-form-item>
          <el-form-item label="Confirm password">
            <el-input
              v-model="form.requirePas"
              placeholder="Please enter your password again"
              show-password
            ></el-input>
          </el-form-item>
          <div class="submit" @click="submitEn()">Confirm</div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { checkExist, pwdChange } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
export default {
  name: "ChangePhone",
  components: {},
  data() {
    return {
      title: "修改密码",
      titleEn: "Modify password",
      labelPosition: "right",
      form: {
        oldpassword: "",
        password: "",
        requirePas: "",
      },
      hasOldPassword: "false",
      disabled: false,
      setCode: "发送验证码",
      setCodeEn: "Verification code",
      languageSelect: "",
    };
  },
  computed: {},
  watch: {
    "$route.path": {
      handler(toPath, fromPath) {
        console.log(toPath, fromPath);
        this.checkExist();
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气人才",
        text: "洞悉油气行业变化，体验AI大模型，就来油气人才！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    // 查询是否设置设置密码
    checkExist() {
      console.log("查询是否设置设置密码");
      let data = {
        access_token: localStorage.getItem("Zyaccess_token"), // 竹云token
      };
      checkExist(data).then((res) => {
        console.log(res, "res");
        this.hasOldPassword = res.data;
      });
    },
    cancleSubmit(){
      this.$router.push("/secure")
    },
    submit() {
      if (this.hasOldPassword == "true" && !this.form.oldpassword) {
        this.$message.error("请输入旧密码");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("请输入密码");
        return false;
      }
      if (!this.form.requirePas) {
        this.$message.error("请再次输入密码");
        return false;
      }
      if (this.form.password !== this.form.requirePas) {
        this.$message.error("两次密码不一致");
        return false;
      }
      let data = {
        token: localStorage.getItem("Zyaccess_token"), // 竹云token
        password: this.form.password,
      };
      if (this.hasOldPassword == "true") {
        data.oldPassword = this.form.oldpassword;
      }
      pwdChange(data).then((res) => {
        if (!res.data) {
          this.$message({
            message: "修改成功！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              this.$router.go(-1);
            },
          });
        } else {
          let data1 = JSON.parse(res.data);
          this.$message({
            message: data1.error_msg,
            type: "error",
          });
        }
      });
      console.log(this.form);
    },

    submitEn() {
      if (this.hasOldPassword == "true" && !this.form.oldpassword) {
        this.$message.error("Please enter old password");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("Please enter password");
        return false;
      }
      if (!this.form.requirePas) {
        this.$message.error("Please enter your password again");
        return false;
      }
      if (this.form.password !== this.form.requirePas) {
        this.$message.error("Two passwords do not match");
        return false;
      }
      let data = {
        token: localStorage.getItem("Zyaccess_token"), // 竹云token
        password: this.form.password,
      };
      if (this.hasOldPassword == "true") {
        data.oldPassword = this.form.oldpassword;
      }
      pwdChange(data).then((res) => {
        if (!res.data) {
          this.$message({
            message: "修改成功！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              this.$router.go(-1);
            },
          });
        } else {
          let data1 = JSON.parse(res.data);
          this.$message({
            message: data1.error_msg,
            type: "error",
          });
        }
      });
      console.log(this.form);
    },
  },
};
</script>
<style scoped lang="scss">
.mainContain {
  width: 65vw;
  border-radius: 9px;
  margin: 0 1vw;
  height: 75vh;
  padding: 0 2vw 3vh 0;
  position: relative;
  background-image: url("../../assets/oilba.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;

}
.box {
  padding: 2vh 0;
  border-radius: 9px;

  .ViewTitle {
    height: 8vh;
    line-height: 8vh;
    border-bottom: 1px solid #ebebeb;
    font-size: 1vw;
    color: #333333;
    font-weight: 400;
    padding: 0 3vw;
  }
  .contain {
    padding: 5vh 3vw;
    width: fit-content;
    .tips {
      display: flex;
      align-items: center;
      margin-bottom: 3vh;
      font-size: 0.9vw;;
      color: #666;
      img {
        width: 2.5vh;
        height: 2.5vh;
        margin-right: 10px;
      }
    }
    .el-form-item {
      margin-bottom:4vh;
    }

    .submit {
      width: fit-content;
      height: 6vh;
      line-height: 6vh;
      text-align: center;
      border-radius: 9px;
      position: absolute;
      bottom:5vh;
      right:5vw;
      color: #ffffff;
      cursor: pointer;

    }
    .cancleStyle {
      margin-top: 1vh;
      width: fit-content;
      padding: 0 3vw;
      height: 6vh;
      border-radius: 9px;
      opacity: 1;
      border: 1px solid #bf0022;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #bf0022;
      line-height: 20px;
      -webkit-background-clip: text;
    }
    .confirmStyle {
      margin-top: 1vh;
      width: fit-content;
      padding: 0 3vw;
      height: 6vh;
      border-radius: 9px;
      margin-left: 2vw;
      cursor: pointer;
      border: none;
      background-color: #bf0022;
      font-size: 16px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

::v-deep .el-input__prefix {
  left: 90%;
  transition: all 0.3s;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: white !important;
  border: #575757 1px solid !important;
  //color: #c0c4cc;
  cursor: not-allowed;
}

::v-deep .el-input .el-input__inner {
  background-color: white !important;
  border: #575757 1px solid !important;
  //color: #c0c4cc;
  height: 6vh;
}

::v-deep {
  .el-input__inner {
    height: 48px;
  }

  .el-form-item__label {
    color: #666;
    font-size: 0.9vw;;
    padding-right: 32px;
    line-height: 48px;
  }

  .codeBox {
    .el-form-item__content {
      display: flex;

      .el-input {
        flex: 1;
      }

      .setCode {
        width: 120px;
        height: 48px;
        // line-height: 50px;
        text-align: center;
        border-radius: 4px;
        background: var(--main-color);
        color: #fff;
        font-size: 14px;
        margin-left: 15px;
      }
    }
  }
}
</style>
